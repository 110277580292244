import React, { Fragment, lazy } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from "@material-ui/icons/Menu";
import UserIcon from '@material-ui/icons/Person';
import BackIcon from '@material-ui/icons/Undo';
import isEmpty from '../../../wumdrophubsreactshared/_utils/isEmpty';
import headerStyle from "./Header.css";
import logo from 'logo.svg';
import NewDeliveryButton from "../../common/buttons/NewDeliveryButton";
import Box from "@material-ui/core/Box";
import { GetEnvironmentAsync } from "devenvironmentutil";
import LoginButton from "components/common/buttons/LoginButton";
import { withWidth } from "@material-ui/core";
import packageJson from '../../../../package.json'; // Adjust the path if needed


const DrawerContent = lazy(() => import('./DrawerContent'));

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      environment: "local",
      drawerOpen: false,
      anchorEl: null
    };
  }

  handleDrawerToggle = () => {
    this.setState((prevState) => ({
      drawerOpen: !prevState.drawerOpen
    }));
  }
  
  async componentDidMount() {
    if (this.props.changeColorOnScroll) {
      window.addEventListener("scroll", this.headerColorChange);
    }
    GetEnvironmentAsync().then((environment) => {
      this.setState({ environment });
    });
  }
  headerColorChange = () => {
    const { classes, color, changeColorOnScroll } = this.props;
    const windowsScrollTop = window.pageYOffset;
    if (windowsScrollTop > changeColorOnScroll.height) {
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[changeColorOnScroll.color]);
    } else {
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[changeColorOnScroll.color]);
    }
  }
  componentWillUnmount() {
    if (this.props.changeColorOnScroll) {
      window.removeEventListener("scroll", this.headerColorChange);
    }
  }

  handleLoggedInMenuOpen = event => {
    const { auth } = this.props;
    if (auth.previousUsers.length > 0) {
      this.setState({ anchorEl: event.currentTarget });
    } else {
      this.props.history.push('/profile');
    }
  };

  handleSwitchToUser = (user) => {
    if (this.props.onSwitchToUser) {

      //this is the function we want to fire for rerouting on successful login
      let navigationFunction = () => window.location.reload();

      //this is the function for setting localStorage to maintain our JWT auth token
      let setLocalStorageFunction = (key, value) => localStorage.setItem(key, value);

      //this is the function for getting localStorage item
      let getLocalStorageFunction = (key) => localStorage.getItem(key);

      //this is the function for getting localStorage item
      let removeLocalStorageFunction = (key) => localStorage.removeItem(key);

      this.props.onSwitchToUser(user, navigationFunction, setLocalStorageFunction, getLocalStorageFunction, removeLocalStorageFunction);
    }
    this.setState({ anchorEl: null });
  };

  handleLoggedInMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl, environment } = this.state;
    const { classes, color, fixed, absolute, portal, auth, fullWidth, onOpenDeliveryWizard } = this.props;

    const appBarClasses = classNames({
      [classes.appBar]: true,
      [classes[color]]: color,
      [classes.absolute]: absolute,
      [classes.fixed]: fixed,
      [classes.fullWidth]: fullWidth
    });

    const toolBarClasses = classNames({
      [classes.container]: true,
      [classes.toolBarFullWidth]: fullWidth
    });

    const buttonClasses = classNames({
      [classes.title]: true,
      [classes.buttonFullWidth]: fullWidth
    });

    const isMenuOpen = Boolean(anchorEl);

    const renderLoggedInMenu = (
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={this.handleLoggedInMenuClose}
      >
        {!isEmpty(auth.currentUser) &&
          <MenuItem key={0} className={classes.backToUserItem} onClick={() => this.props.history.push('/profile')}>
            <UserIcon className={classes.backToUserIcon} /> {auth.currentUser.user.firstName}'s profile
          </MenuItem>}
        {auth.previousUsers.map(user => {
          return <MenuItem key={user.id} className={classes.backToUserItem} onClick={() => this.handleSwitchToUser(user)}>
            <BackIcon className={classes.backToUserIcon} /> Back to {user.email}
          </MenuItem>
        })
        }

      </Menu>
    );

    return (
      <div>
        <AppBar className={appBarClasses}>
          <Toolbar className={toolBarClasses}>
            <Button className={buttonClasses}>
              <Link className={classes.logoLink} to='/'>
                <img src={logo} alt="logo" width="126" height="33" />
                {!isEmpty(portal.data.flag) ? <span className={classes.flag}>{portal.data.cca2}</span> : ""}
              </Link>
            </Button>
            {!environment.toLocaleLowerCase().includes("production") && <div className={`${classes.platformBtn}`}>{`${environment}`}</div>}
            <Hidden mdDown>
              {
                !fullWidth &&
                <div style={{ width: '100%' }}> 
                  <Box display="flex" justifyContent="flex-end">
                    <Box p={1}>
                      <Button className={classes.menuItemsBar} component={Link} to="/pricing" >Pricing</Button>
                    </Box>
                    <Box p={1}>
                      <Button className={classes.menuItemsBar} component={Link} to="/businesses" >For businesses</Button>
                    </Box>
                    <Box p={1}>
                      <Button  className={classes.menuItemsBar} component={Link} to="/drivers" >For drivers</Button>
                    </Box>
                    <Box p={1}>
                      <Button className={classes.menuItemsBar} component={Link} to="/developers" >For developers</Button>
                    </Box>
                    <Box p={1}>
                      <Button className={classes.menuItemsBar} component={Link} to="/track-order" >Track my order</Button>
                    </Box>
                  </Box>
                </div>
              }
            </Hidden>

            <Fragment>
            <div style={{ flex: 1 }} />
              <Hidden mdDown>
                {!auth.isAuthenticated ?
                  <LoginButton />
                  :
                  <NewDeliveryButton fullWidth={false} onOpenDeliveryWizard={onOpenDeliveryWizard} auth={auth} />
                }
              </Hidden>
                {auth.isAuthenticated && 
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={this.handleDrawerToggle}
                  >
                    <MenuIcon />
                  </IconButton>
              }
              {auth.isAuthenticated && 
                <Drawer
                  anchor="right"
                  open={this.state.drawerOpen}
                  onClose={this.handleDrawerToggle}
                  classes={{
                    paper: classes.drawerPaper
                  }}
                >
                  <DrawerContent handleLoggedInMenuOpen={this.handleLoggedInMenuOpen} onOpenDeliveryWizard={onOpenDeliveryWizard} fullWidth={fullWidth} />
                  <p style={{bottom: 0, position: "absolute", marginLeft: "35px", marginBottom: "20px"}}>version  <span style={{ fontSize: 14}}>{packageJson.version}</span></p>
                </Drawer>} 
            </Fragment>
          </Toolbar>
        </AppBar>
        {auth.impersonating && renderLoggedInMenu}
      </div>
    );
  }
}

Header.defaultProp = {
  color: "white"
};

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "transparent",
    "white",
    "rose",
    "dark",
  ]),
  links: PropTypes.node,
  brand: PropTypes.string,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  fullWidth: PropTypes.bool,
  // this will cause the sidebar to change the color from
  // this.props.color (see above) to changeColorOnScroll.color
  // when the window.pageYOffset is heigher or equal to
  // changeColorOnScroll.height and then when it is smaller than
  // changeColorOnScroll.height change it back to
  // this.props.color (see above)
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "info",
      "success",
      "warning",
      "danger",
      "transparent",
      "white",
      "rose",
      "dark",
    ]).isRequired
  })
};

export default withStyles(headerStyle)(withWidth()(Header));
