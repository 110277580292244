import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import { StepLabel } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import styles from './DeliveryStepper.css';
import { compose } from 'react-recompose';
import MobileStepper from '@material-ui/core/MobileStepper';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import CircularProgress from '@material-ui/core/CircularProgress';
import isEmpty from 'wumdrophubsreactshared/_utils/isEmpty';
import isEqual from 'wumdrophubsreactshared/_utils/isEqual';
import { clearErrors } from 'wumdrophubsreactshared/_actions/errors';
import { getQuotes, createQuotes, addParcel, updateParcel, removeParcel, updateParcels, updateQuotes, selectQuote, updateOriginQuoteDetails, updateDestinationQuoteDetails, updateAdditionalQuoteDetails, acceptQuote, paymentFailed, clearQuotesSuccess } from 'wumdrophubsreactshared/_actions/quotes';
import { setDeliveryWizardProps } from 'wumdrophubsreactshared/_actions/navigation';
import { clearDeliveriesSuccess } from 'wumdrophubsreactshared/_actions/deliveries';
import { PAYMENT_SUCCESS, PAYMENT_FAILED } from 'wumdrophubsreactshared/_constants/typeConstants';
import { PAYMENT_STATUS_ENUMS } from 'wumdrophubsreactshared/_constants/apiConstants';
import combineStyles from "wumdrophubsreactshared/_utils/combineStyles";
import appStyles from "App.css";

import DeliveryStep from './step1/DeliveryStep';
import ParcelStep from './step2/ParcelStep';
import QuoteStep from './step3/QuoteStep';
import DetailsStep from './step4/DetailsStep';
import AcceptStep from './step5/AcceptStep';

import DeliveryStepperPaidSummary from './step6/DeliveryStepperPaidSummary';
import DeliveryStepperNegativeSummary from './step6/DeliveryStepperNegativeSummary';

const PaymentStepper = lazy(() => import('./step6/PaymentStepper'));

const steps = ['Delivery', 'Parcels', 'Quotes', 'Details', 'Accept', 'Pay'];

class DeliveryStepper extends React.Component {

  constructor(props) {
    super(props);
    let activeStep = 0;
    let detailsActiveStep = 0;

    if (!isEmpty(this.props.step)) {
      activeStep = this.props.step;
    }

    this.state = {
      success: false,
      activeStep: activeStep,
      nextStep: activeStep,
      paid: false,
      detailsActiveStep: detailsActiveStep,
    }
  }

  componentWillUnmount() {
    this.props.clearErrors();
  }

  componentDidUpdate(prevProps) {
    const { deliveryWizardProps } = this.props.nav;

    let paymentStatusId = this.props.quotes.data.paymentStatusId;
    let prevPaymentStatusId = prevProps.quotes.data.paymentStatusId;

    if (prevPaymentStatusId !== paymentStatusId) {
      if (paymentStatusId === PAYMENT_STATUS_ENUMS.PAID) {
        this.setState({ paid: true });
      }

      if (paymentStatusId === PAYMENT_STATUS_ENUMS.PAYMENT_FAILED) {
        this.setState({ paid: false });
      }
    }

    // this is for reseting the quote picker back to initial state
    if (isEmpty(this.props.quotes.data) && !this.props.quotes.loading && (this.props.step !== prevProps.step)) {
      this.setState({ activeStep: this.props.step });
      this.props.setDeliveryWizardProps(deliveryWizardProps.open, deliveryWizardProps.loading, this.props.step);
    }

    //reload the quote
    if (!isEqual(this.props.quotes.data, prevProps.quotes.data) && !isEmpty(this.props.quotes.data) && !this.props.quotes.loading) {
      const { nextStep } = this.state;
      this.setState({ activeStep: nextStep });
      this.props.setDeliveryWizardProps(deliveryWizardProps.open, deliveryWizardProps.loading, nextStep);

    }

    if (!isEqual(this.props.errors, prevProps.errors)) {
      const { activeStep } = this.state;
      this.setState({ nextStep: activeStep });
    }
  }


  handleStepSubmit = (request) => {
    const { activeStep, detailsActiveStep } = this.state;
    const { quotes } = this.props;

    switch (activeStep) {
      case 0:
        if (!isEmpty(quotes) && !isEmpty(quotes.data) && !isEmpty(request.deliveryId))
          this.props.updateQuotes(request, request.deliveryId);
        else
          this.props.createQuotes(request);
        break;
      case 1:
        this.props.updateParcels(request);
        break;
      case 2:
        this.props.selectQuote(request.deliveryId, request.quoteId).then(() => {
          this.setState({ detailsActiveStep: 0 });
        });
        break;
      case 3:
        //the actions here are called in the DetailsStep.js
        switch (detailsActiveStep) {
          case 0:

            this.props.updateOriginQuoteDetails(request, request.deliveryId).then(() => {
              if (isEmpty(this.props.errors.message)) {
                this.setState({ detailsActiveStep: detailsActiveStep + 1 });
              }
            });
            break;
          case 1:
            this.props.updateDestinationQuoteDetails(request, request.deliveryId).then(() => {
              if (isEmpty(this.props.errors.message)) {
                this.setState({ detailsActiveStep: detailsActiveStep + 1 });
              }
            });
            break;
          default:
            this.props.updateAdditionalQuoteDetails(request, request.deliveryId);
        }
        break;
      case 4:
        this.props.acceptQuote(request.deliveryId);
        break;
      case 5:
        if (request.paymentStatus === PAYMENT_SUCCESS) {
          this.props.paymentSuccess(request);
        } else if (request.paymentStatus === PAYMENT_FAILED) {
          this.props.paymentFailed(request);
        }
        break;
      default:
        this.props.handleClose();
        break;
    }

    //clear the quotes key so we can get the response back from the server and know when to display the next view
    if (this.props.clearQuotesSuccess) {
      this.props.clearQuotesSuccess();
    }


    if (activeStep === 3 && detailsActiveStep !== 2) {
      // loader is controlled by the details step itself

    }
    else if (activeStep < 5) {
      this.setState({ nextStep: activeStep + 1 });
    }
  };

  submitMyForm = null;

  handleSubmitMyForm = (e) => {
    if (this.submitMyForm) {
      this.submitMyForm(e);
    }
  };

  bindSubmitForm = (submitForm) => {
    this.submitMyForm = submitForm;
  };

  getStepContent() {
    const { activeStep, detailsActiveStep } = this.state;
    const { quotes } = this.props;

    //NB! this wizardRef (a unique GUID) needs to be set in the wizard initially so we can tie it back to each of steps in the process
    const { wizardRef } = this.props;

    switch (activeStep) {
      case 0:
        return <DeliveryStep quoteResponse={quotes.data} handleStepSubmit={this.handleStepSubmit} wizardRef={wizardRef} />;
      case 1:
        return <ParcelStep quoteResponse={quotes.data} loading={quotes.loading} handleStepSubmit={this.handleStepSubmit} addParcel={this.props.addParcel} updateParcel={this.props.updateParcel} removeParcel={this.props.removeParcel} wizardRef={wizardRef} />;
      case 2:
        return <QuoteStep quoteResponse={quotes.data} loading={quotes.loading} onChangeOrderClick={this.handleChangeOrderClick} handleStepSubmit={this.handleStepSubmit} wizardRef={wizardRef} />;
      case 3:
        return <DetailsStep quoteResponse={quotes.data}
          loading={quotes.loading}
          detailsActiveStep={detailsActiveStep}
          bindSubmitForm={this.bindSubmitForm}
          handleStepSubmit={this.handleStepSubmit} wizardRef={wizardRef} />;
      case 4:
        return <AcceptStep quoteResponse={quotes.data} handleStepSubmit={this.handleStepSubmit} wizardRef={wizardRef} />;
      case 5:
        return <PaymentStepper quoteResponse={quotes.data} handleStepSubmit={this.handleStepSubmit} wizardRef={wizardRef} />
      default:
        return 'Unknown step';
    }
  }

  renderNextSwitch(activeStep) {
    switch (activeStep) {
      //   case 4: //accept delivery (update statuses)
      //     return "Accept and pay";
      case 5: //update payment
        return "Finish";
      default:
        return "Next";
    }
  }

  handleBack = () => {
    const { activeStep, detailsActiveStep } = this.state;

    if (activeStep === 3 && detailsActiveStep !== 0) {
      this.setState({ detailsActiveStep: detailsActiveStep - 1 });
    }
    else {
      this.setState(state => ({ activeStep: state.activeStep - 1, nextStep: state.activeStep - 1 }));
    }
  };

  handleStepClick = step => () => {
    const { deliveryWizardProps } = this.props.nav;

    this.setState({
      activeStep: step,
      nextStep: step
    });
    this.props.setDeliveryWizardProps(deliveryWizardProps.open, deliveryWizardProps.loading, step);
  };

  handleChangeOrderClick = () => {
    this.setState({
      activeStep: 0,
      nextStep: 0
    });
  }

  render() {
    const { classes, wizardRef, handleClose, quotes } = this.props;
    const { activeStep, paid, detailsActiveStep } = this.state;

    let disableBack = activeStep === 0 ? true : paid;

    //NB! this formId needs to be set in the wizard initially so we can tie it back to each of steps in the 
    //getStepContent above
    let formId = `${wizardRef}${(activeStep + 1)}`;

    // let loading = !isEmpty(nav.deliveriesLoading) && (nav.deliveriesLoading.includes(quotes.data.deliveryId) || nav.deliveriesLoading.includes(-1));
    return (
      <div className={classes.root}>
        {isEmpty(quotes.data) && activeStep !== 0 ? <div className={classes.centerAlign}><CircularProgress /></div> :
          isEmpty(quotes.data) || (quotes.data.paymentStatusId >= PAYMENT_STATUS_ENUMS.ITEMS_OUTSTANDING
            && quotes.data.paymentStatusId < PAYMENT_STATUS_ENUMS.PAID)
            ?
            <div>
              <Stepper activeStep={activeStep} className={classes.stepper}>
                {steps.map((label, index) => {
                  const props = {};
                  return (
                    <Step key={label} {...props} className={classes.stepHorizontal}>
                      {activeStep > index ?
                        <StepButton disabled={paid} onClick={this.handleStepClick(index)} className={classes.stepButton}>
                          <StepLabel
                            StepIconProps={{
                              classes: { completed: classes.stepIcon, active: classes.stepIconActive }
                            }}>
                            {label}
                          </StepLabel>
                        </StepButton>
                        :
                        <StepLabel
                          StepIconProps={{
                            classes: { completed: classes.stepIcon, active: classes.stepIconActive }
                          }}>
                          {label}
                        </StepLabel>
                      }
                    </Step>
                  );
                })}
              </Stepper>
              <Typography className={classes.stepHeader}>Step {activeStep + 1} of 6: <span className={classes.stepTitle}>{steps[activeStep]}</span></Typography>
              {(quotes.loading && activeStep !== 1 && activeStep !== 2 && activeStep !== 3 && activeStep !== 5) ? // here step 1 (packages) step 2 (quotes), step 3 (details) and step 5 (payment) control their own loaders
                <div className={classes.progress}>
                  <CircularProgress />
                </div> :
                <div className={classes.stepContent}>
                  {this.getStepContent()}
                </div>
              }

              <MobileStepper
                variant="progress"
                steps={6}
                position="static"
                activeStep={this.state.activeStep}
                className={classes.mobileStepper}
                LinearProgressProps={{
                  classes: {
                    barColorPrimary: classes.barColor, // class name, e.g. `classes-nesting-root-x`
                    colorPrimary: classes.barColorBackground, // class name, e.g. `classes-nesting-label-x`
                  }
                  // style: {backgroundColor: lighten(SECONDARY_COLOR, 0.2)},
                }}
                nextButton={
                  !quotes.loading ?
                    activeStep === 3 && detailsActiveStep !== 2 ?
                      <div className={classes.buttonSpacer} />
                      :
                      activeStep === 5 ?
                        <Button size="small" disabled={(this.state.activeStep === 5 && !paid)} className={classes.bottomButton} onClick={handleClose}>
                          {this.renderNextSwitch(activeStep)}
                        </Button>
                        :
                        <Button size="small" form={formId} type="submit" className={classes.bottomButtonGreen}>
                          {this.renderNextSwitch(activeStep)}
                          {<KeyboardArrowRight />}
                        </Button>
                    : <div className={classes.buttonSpacer} />
                }
                backButton={
                  !quotes.loading ?
                    <Button size="small" onClick={this.handleBack} disabled={disableBack} className={classes.bottomButton}>
                      {<KeyboardArrowLeft />}
                      Back
                    </Button>
                    : <div className={classes.buttonSpacer} />
                }
              />
            </div>

            :
            <div className={classes.summaryContent}>
              {(!isEmpty(quotes.data) && quotes.data.paymentStatusId === PAYMENT_STATUS_ENUMS.PAID)
                ? <DeliveryStepperPaidSummary quoteResponse={quotes.data} />
                : <DeliveryStepperNegativeSummary />}
            </div>
        }
      </div>
    );
  }
}

DeliveryStepper.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  errors: state.errors,
  deliveries: state.deliveries,
  quotes: state.quotes,
  portal: state.portal,
  nav: state.nav
});

const combinedStyles = combineStyles(styles, appStyles);

export default compose(
  withStyles(combinedStyles, { withTheme: true }, { name: 'DeliveryStepper' }),
  connect(mapStateToProps, { setDeliveryWizardProps, getQuotes, createQuotes, updateQuotes, addParcel, updateParcel, removeParcel, updateParcels, selectQuote, updateOriginQuoteDetails, updateDestinationQuoteDetails, updateAdditionalQuoteDetails, clearErrors, clearDeliveriesSuccess, acceptQuote, paymentFailed, clearQuotesSuccess }),
)(DeliveryStepper);